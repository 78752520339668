export default {
  address: '高雄市鼓山區青海路490號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3682.05294856209!2d120.28106821530716!3d22.65181438514217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x346e04457e85ad19%3A0xc6ec24adf943a722!2z5aCF5bGx6KyZ5Luw!5e0!3m2!1szh-TW!2stw!4v1571125780323!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/X9dQ4E2vTtt7Jam3A',
  phone: '07-5538880',
  fbLink: 'https://www.facebook.com/堅山建設-116432019762108/',
  fbMessage: 'https://m.me/116432019762108',
  caseName: '堅山謙仰',

  houseInfos: [
    ['投資興建', '堅山建設股份有限公司'],
    ['坪數規劃', '四房(82坪、93坪)'],
    ['建築設計', '弘憲聯合建築師事務所 李維強建築師'],
    ['公設設計', '陳嘉民建築空間設計'],
    ['基地面積', '623.3坪'],
    ['樓層規劃', '28F/B4']
  ],

  gtmCode: ['N57JXD2'], // 可放置多個
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}